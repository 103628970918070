.App {
    min-height: 100vh;
    background-color: #E1E0D8;
    width: 100%;
}

.container {
    max-width: var(--max-width);
    margin: auto;
    position:relative;
}

.container_fulid {
    margin: auto;
}