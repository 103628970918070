:root {
  --primary-color: #000000;
  --title-black:#252525;
  --light-grey:#000000A3;
  --lighter-grey:#767676a3;
  --sflc-red:#BD0101;
  --container_1_width: 1208px;
  --container_2_width: 698px;
  --white_color: rgb(249, 249, 249);
  --card-background: rgba(210, 209, 202, 1);
  --background: rgba(225, 224, 216, 1);
  --sflc-yellow:#FFD450;
  --gap: 56px;
  --border: 1px solid black;
  --max-width: 1208px;
  --line: rgba(0, 0, 0, 0.34);

}
  @font-face {
        font-family: 'Roboto Slab';
        font-display: swap;
        font-optical-sizing: auto;
        src: url('./fonts/RobotoSlab-VariableFont_wght.ttf') format('truetype');
    }
    @font-face {
      font-family: 'Epilogue';
      font-display: swap;
      font-optical-sizing: auto;
      src: url('./fonts/Epilogue-VariableFont_wght.ttf') format('truetype');
  }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.line {
  width: 100%;
  background-color: #000000A3;
  height: 1px;
}

.line_light {
  width: 100%;
  background-color: #FFFFFF;
  height: 1px;
}

//svg icons class

.copyIcon{
  fill: #252525;
}
.link_icon{
  fill:#1C1B1F;
}

.slim_container
{
    width:678px;
    margin: auto;
    max-width: 678px;
}

.slim_chart_container
{
  width:815px;
  margin: auto;
  max-width: 915px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.charts_container
{
  max-width: 77%;
  width: 100%;
  // width: 87%;
  // margin: auto;
  display: flex;
  justify-content: center;
  // align-items: center;
  margin: 56px auto;
}
.charts_container.charts_100
{
  width: 100%;
  max-width: 100%;
}

.charts_container_wrapper{
  width: 100%;
}

.apexcharts-xaxis-label
{
  margin-bottom: 20px;
  padding: 20px;
}

.bread_crumbs {
  display: flex;

  .bread_crumbs_pre {
    font-family: Epilogue;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);

    &::after {
      content: '/';
      padding: 0px 12px;
    }
  }
  .bread_crumbs_pre.dark{
    color: var(--background);
  }

  .bread_crumbs_cur {
    font-family: Epilogue;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
}
.bread_crumbs_cur.dark{
  color: var(--background);
}

.bread_crumbs[theme=dark] {
  display: flex;

  .bread_crumbs_pre {
    color: #FFFFFF73;
  }

  .bread_crumbs_cur {
    color: #FFFFFFD9;
  }
}

.blog_heading {
  width: 678px;
  color: #252525;
  font-size: 36px;
  font-family: Libre Baskerville;

  font-weight: 400;
  word-wrap: break-word;
}

.body {
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.6;
  text-align: justify;
  letter-spacing: 0.5px;
  color: #000000;
  p{
    margin-top: 16px;
    a{
      text-decoration: none;
      color: var(--title-black);
      text-decoration: underline;
    }
  }
  sup{
    font-size: 12px;
    cursor: pointer;
    a{
      text-decoration: none;
      color: var(--light-grey);
    }
  }
}

.no_text_decoration {
  text-decoration: none;
}

.title {
  font-family: Epilogue;
  font-size: 28px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.web_1368 {
  .chart {
    .title {
      font-family: Epilogue;
      font-size: 28px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
    }

    .subtitle {
      font-family: 'Epilogue';
      font-size: 17.26px;
      font-weight: 700;
      line-height: 15.44px;
      text-align: left;
    }

    .data_label {
      font-family: Epilogue;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }

    .sub_label {
      font-family: Epilogue;
      font-size: 17.26px;
      font-style: italic;
      font-weight: 700;
      line-height: 14px;
      text-align: left;
    }

    .axis_label {
      //styleName: Charts/Axis labels;
      font-family: Epilogue;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;

      a {
        font-family: Epilogue;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
      }
    }

    .legends_label {
      font-family: Epilogue;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }
  }


  .card {
    .title {
      font-family: Epilogue;
      font-size: 30px;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: 0.25px;
      text-align: left;
    }

    .subtitle {
      font-family: Epilogue;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: rgba(0, 0, 0, 0.64);
    }

    .label {
      font-family: Epilogue;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }
  }

  .article {
    .main_title {
      //styleName: Article/Main Title;
      font-family: Epilogue;
      font-size: 57px;
      font-weight: 700;
      line-height: 58.43px;
      text-align: left;
    }

    .sub_title {
      //styleName: Article/Sub-title;
      font-family: Epilogue;
      font-size: 28px;
      font-weight: 400;
      line-height: 42px;
      text-align: left;
    }

    .report_button {
      //styleName: Article/Report button;
      font-family: Epilogue;
      font-size: 19.09px;
      font-weight: 700;
      line-height: 19.57px;
      text-align: left;
    }

    .body {
      //styleName: Article/Body;
      font-family: Epilogue;
      font-size: 22px;
      font-weight: 400;
      line-height: 33px;
      letter-spacing: 0.5px;
      text-align: justified;
    }

    .section_title {
      //styleName: Article/Article section title;
      font-family: Epilogue;
      font-size: 32px;
      font-style: italic;
      font-weight: 700;
      line-height: 48px;
      text-align: left;

    }

    .info_popup {
      //styleName: Article/Info popup;
      font-family: Epilogue;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
    }
  }

  .form {
    .subtitle {
      //styleName: Form/Subtitle;
      font-family: Epilogue;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      text-align: left;
    }

    .text {
      //styleName: Form/Text;
      font-family: Epilogue;
      font-size: var(--Fontsizes18px);
      font-weight: 400;
      line-height: 22px;
      text-align: left;
    }
  }
}

@media(max-width:600px){
  .charts_container
{

  width: 100%;
  max-width: 100%;
  // width: 87%;
  // margin: auto;
  display: block;
  // justify-content: center;
  // align-items: center;
  margin: 56px auto;
  overflow: scroll;
}

.charts_container.no_scroll{
  overflow: visible;
}
.charts_container_wrapper{
  width: 800px;
}
.slim_container{
  width: 100%;
}
.slim_chart_container{
  width: 100%;
}
}