.case_studies {
    background-color: var(--card-background);
    border: var(--border);
    display: flex;
    height: 487px;
    margin-bottom: 56px;
}

.content {
    padding: 24px;
    width: 38%;
    display: flex;
    flex-direction: column;

    .heading {
        font-family: Epilogue;
        font-size: 34px;
        font-style: italic;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0.0025em;
        text-align: left;
    }

    .caption {
        gap: 4px;
        border-radius: 2px;
        width: fit-content;
        margin-top: 36px;
        padding: 2px 4px;
        border-radius: 2px;
        display: flex;
        background-color: var(--background);

        .left_line {
            width: 4px;
            height: 16px;
        }
    }

    .title {
        padding: 20px 0px;
        a{
            text-decoration: none;
            color:var(--title-black);
        }
    }
    .subtitle{
        a{
            text-decoration: none;
            color:var(--light-grey);
        }
    }

    .gap {
        flex-grow: 1;
    }

    .nav {
        display: flex;
        gap: 10px;

        .left_nav {
            cursor: pointer;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background-color: rgba(37, 37, 37, 0.08);
            padding: 12px;
        }

        .right_nav {
            cursor: pointer;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background-color: rgba(37, 37, 37, 0.08);
            padding: 12px;
        }
    }
}

.center {
    padding: 24px;
    width: 12%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .view_all {
        font-family: Epilogue;
        font-size: 14px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(37, 37, 37, 1);
        padding: 0px 16px;
        cursor: pointer;
        text-transform: uppercase;
    }

    .selected {
        font-family: Epilogue;
        font-size: 14px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0.0025em;
    }
}

.image {
    width: 50%;
    object-fit: cover;
}

@media(max-width:600px)
{
    .case_studies{
        flex-direction: column;
        height: unset;
    } 
    .content{
        width: 100%;
        gap: 10px;
      
    }
    .center{
        width: 100%;
        flex-direction: row;
        margin-top: -30px;
    }
    .image{
        width: 100%;
    }
}