.question {
    margin-top: 48px;
    font-family: Epilogue;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);

    &::before {
        content: "* ";
        color: red;
    }
}

.input {
    flex-grow: 1;
    border-radius: 2px;
    padding-left: 12px;
    border: 1px solid rgba(0, 0, 0, 1);
    background-color: white;
    min-height: 32px;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        margin-right: 8px;
        -webkit-appearance: none;
        transform: scale(1.5);
        background-image: url('/vector/calendar.png');
    }
}