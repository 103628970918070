.container
{
    font-family: Epilogue;
    .header_img
    {
        max-width: 100%;
        width: 100%;
        margin-top: 49px;
        margin-bottom: 64px;
    }
    .header
    {
        .title
    {
        margin-top: 16px;
        font-size: 57px;
        font-weight: 700;
        color:var(--title-black);
    }
    .sub_heading{
        color: var(--light-grey);
        font-size: 28px;
        font-weight: 400;
        padding: 16px 0px;
        margin-bottom: 16px;
        line-height: 42px;
        border-bottom: 1px solid var(--lighter-grey);
    }
    .share_tab 
    {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        .report_ban_btn
        {
            outline: none;
            padding: 16px 12px;
            font-size: 20px;
            color: var(--sflc-red);
            background: none;
            font-weight: 700;
            border: 2px solid var(--sflc-red);
            transition: ease-in-out 0.3s;
            .arrowSvg
            {
                fill: var(--sflc-red);
                transition: ease-in-out 0.3s;
            }
            &:hover{
                background: var(--sflc-red);
                color: var(--white_color);
                cursor: pointer;
                .arrowSvg
                {
                    fill: var(--white_color);
                }
            }
        }

    }
    }
    
}


// description style
.description
{
    font-family: Epilogue;
    font-size:22px ;
    line-height: 33px;
    text-align: justify;
}

.download_section
{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 32px;
    button{    
        cursor: pointer;
    &:first-child{
      background-color: var(--background);  
        color: var(--title-black);
        border: unset;
        font-size: 28px;
        line-height: 38px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Epilogue;
        background: #25252533;
    }
    &:last-child{
        background: var(--sflc-red);
        color: var(--background);
        border: unset;
        font-size: 28px;
        line-height: 38px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Epilogue;
    }
    }
}

.download_btn{
    width: 20px;
    height: 20px;
    path{
        fill: var(--title-black);
    }
}
.download_arrow
{
    fill: var(--background);
}

@media (max-width:600px){
    .container{
        max-width: 100%;
    }
    .header{
        width: 100%;
    }
    .description{
        width: 100%;
    }

    .download_section
{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 32px;
    button{    
        cursor: pointer;
    svg{
        margin-left: 20px;
    }
    }
}
}
