.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 674px;
    padding: 21px 48px;
    background-color: rgba(68, 50, 94, 1);
    font-family: Epilogue;

    .question {
        font-family: Epilogue;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        align-self: flex-start;
        text-align: left;
        color: rgba(225, 224, 216, 1);
    }

    .instructions {
        font-family: Epilogue;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: center;
        align-self: flex-start;
        color: rgba(225, 224, 216, 0.64);
        margin-bottom: 95px;
    }

    .options_default {
        width: 100%;
        padding: 10px 16px;
        font-family: Epilogue;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        background-color: transparent;
        border: 1px solid rgba(225, 224, 216, 1);
        margin-bottom: 16px;
        color: rgba(225, 224, 216, 1);
    }

    .selected {
        background-color: rgba(225, 224, 216, 1);
        color: black;
    }

    .selected_corrent {
        background-color: #78A57099;
    }

    .selected_wrong {
        background-color: #D96A6A;
    }

    .text {
        margin-top: 95px;
        margin-top: 8px;
        font-family: Epilogue;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: justified;
        color: rgba(225, 224, 216, 1);
    }

    .image {
        margin-top: 24px;
        height: 164px;
    }

    .button {
        border: none;
        margin-top: 96px;
        background-color: rgba(255, 212, 80, 1);
        width: 185px;
        height: 51px;
        font-family: Epilogue;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.49px;
        text-align: center;
        cursor: pointer;
    }
}
