.header {
    display: flex;
    justify-content: space-between;
    width: var(--max-width);
    padding: 34px 0px;
    align-items: center;
    border-bottom: 1px solid var(--line);

    .heading {
        font-family: Epilogue;
        font-size: 48px;
        font-style: italic;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0.0025em;
        text-align: center;
        color: #252525;
    }

    .filter {
        border: var(--border);
        padding: 16px;
        font-family: Epilogue;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        border-radius: 40px;
        cursor: pointer;
        transition: background-color 0.5s ease-in-out;

        &[active=true] {
            background-color: black;
            color: var(--background);
        }
    }

    .share {
        padding: 12px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: black;
    }
}

.container {
    margin-top: 56px;
    margin-bottom: 56px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    height: min-content;

    &>*>* {
        height: 100%;
    }

    .item1 {
        grid-row: span 2;

        .image {
            width: 100%;
        }
    }

    .item2 {
        grid-column: span 3;
    }
    .item4 {
        grid-column: span 4;
      
    }

    .item3 {
        grid-column: span 3;
        .card{
            flex-direction: row-reverse;
        }
    }
}

.item {
    margin-top: 24px;
    width: 100%;
}

.card {
    width: 100%;
    border: var(--border);
    background-color: var(--card-background);
    padding: 20px;
    display: flex;
    container-type: inline-size;
    gap: 24px;
    text-decoration: none;

    .image {
        width: 240px;
    }

    .content {
        .heading {
            font-family: Epilogue;
            font-size: 34px;
            font-style: italic;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 0.0025em;
            text-align: left;
        }

        .caption {
            gap: 4px;
            border-radius: 2px;
            width: fit-content;
            padding: 2px 4px;
            border-radius: 2px;
            display: flex;
            background-color: var(--background);

            .left_line {
                width: 4px;
                height: 16px;
            }
        }

        .title {
            padding: 20px 0px;
            color: var(--title-black);
        }
        a{
            color: var(--title-black);
        }
    }
}


.description{
    letter-spacing: 1px;
    // text-transform: lowercase;
}

@media(max-width:600px){
    .outer_container{
        padding: 32px 16px;
    }
    .header{
        width: 100%;
        gap: 32px;
        flex-wrap: wrap;
        & >:nth-child(1){
            order: 1;
        }
        & >:nth-child(3){
            order: 2;
        }
        & >:nth-child(2){
            order: 3;
        }
    }
    .filters_outer{
        flex-wrap: wrap;
        max-width: 100%;
    }
    .container{
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 100%;
            grid-row:unset;
            .card{
                display: flex;
                flex-direction: column;
                img{
                    width: 100%;
                }
            }
        }
    }
    
}