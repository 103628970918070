.container
{
   
}
.quiz_section
{
    padding: 192px 273px;
    background: #9C93A240;
}
@media(max-width:600px){
    .quiz_section
    {
        padding: 10px;
    }
    .container{
        padding: 32px 16px ;
    }
    .line_chart_arts svg{
        height: 800px!important;
    }
    .sanky_chart{
        svg {
            text{
                font-size: 10px;
            }
        }
    }
}