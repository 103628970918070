.caption {
    gap: 4px;
    border-radius: 2px;
    width: fit-content;
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
   font-family: Epilogue;
   color: var(--title-black);
   font-weight: 400;
    background-color: var(--background);

    .left_line {
        width: 4px;
        height: 16px;
    }
}