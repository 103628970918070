.container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // filter: blur(2px);
    background-color: rgba(225, 224, 216, 0.826);
    backdrop-filter: blur(2px);
    z-index: 27836748;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.close_container{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
}

.inner_container {
    background-color: rgba(225, 224, 216, 1);
    padding: 26px 16px 16px 80px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 4%;
    border: 1px solid ;
    left: 19%;
    right: 19%;
    z-index: 3212121;
    height: 90vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
        height: 8px; /* Height of the scrollbar (for horizontal scrollbar) */
        margin-right: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Rounded corners of the scrollbar thumb */
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color of the scrollbar thumb on hover */
    }
    
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color of the scrollbar track */
        border-radius: 4px; /* Rounded corners of the scrollbar track */
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .close {
        margin: 16px;
        width: 48px;
        height: 48px;
        cursor: pointer;
    }

    .heading {
        //styleName: Article/Sub-title;
        font-family: Epilogue;
        font-size: 28px;
        font-weight: 400;
        line-height: 42px;
        text-align: left;
        color: rgba(189, 1, 1, 1)
    }
}

.button {
    align-self: end;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    text-transform: uppercase;
    font-family: Epilogue;
    font-size: 19.09px;
    font-weight: 700;
    line-height: 19.57px;
    text-align: left;
    border: none;
    padding: 16px 68px;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        background-color: rgba(0, 0, 0, 0.34);
    }
}

.response_message
{
    display: flex;
    flex-direction: column;
   color:  rgb(120, 165, 112);
   justify-content: center;
   align-items: center;
   
   .button{
    align-self:center;
    margin-top: 16px;
   }

}
.success_message
{
    font-size: 20px;
    margin-top:16px
}
.go_back{
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    font-family: Epilogue;
    color: var(--title-black);
}

@media (max-width:600px){
    .inner_container{
        padding-left: 10px;
        top: 4%;
        border: 1px solid ;
        left: 2%;
        right: 2%;
    }
    .button{
        margin-top: 20px;
    }
}