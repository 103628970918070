.featured {
    --tpc: #000000; // title primary color
    --tsc: #000000; // title secondary color
    --gap: 16px;
    gap: var(--gap);
    display: flex;
    height: fit-content;
    padding: 24px 16px 4px 16px;
    border: 1px solid black;
    background-color: var(--card-background);
}

.featured_image
{
    width: 53.1%;
    position: relative;
    
    small{
        // width: 100%;
        position: relative;
        top: 0px;
    a{
        text-decoration: none;
        color: black;
        font-family: Epilogue
    }
    };
}
.image {
    width: 100%;
    height: auto;
    margin-right:16px;
    position: relative;
    // z-index: -1;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(52.8% - var(--gap));
}

.title {
    color: var(--tpc);
    font-family: Epilogue;
    font-size: 40.5px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.03em;
    text-align: left;

    span:hover {
        background: black;
        cursor:not-allowed;
    }
}

.description {
    color: var(--tsc);
    font-family: Epilogue;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.8;
    word-spacing: 8px;
    margin-top: 16px;

    span {
        text-decoration: underline;
        text-underline-offset: 8px;
    }

    span:hover {
        font-style:italic;
        cursor: pointer;
    }

    .books {
        text-decoration-color: #EF4D50;
    }

    .art {
        text-decoration-color: #78A57099;
    }

    .website {
        text-decoration-color: #82D4E6;
    }

    .social_media {
        text-decoration-color: #F29454;
    }
}

.button {
    margin-top: auto;
    bottom: 0;
    background-color: var(--primary-color);
    color: var( --white_color);
    width: 100%;
    cursor: pointer;
    border: none;
    padding: 16px;
    font-size: 24px;
    font-weight: 700;
    position: relative;
    top: -20px;
}

@media (max-width:1050px){
    .button{
        top: 0px;
    }
}
// mobile view

@media (max-width:600px){
    .featured {
        flex-direction: column;
        margin: 56px 0px;
    }
    .featured_image{
        width: 100%;
    }
    .content{
        width: 100%;
        margin-top: 12px;
    }
    .button{
        margin-bottom:20px;
    }
}