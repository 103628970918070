.book_popup_container{
    left: 0;
    display: flex;
    right: 0;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 935656;
    padding: 26px 36px;
    background: rgba(225, 224, 216, 0.889);
    backdrop-filter: blur(10px);

}
.close_container{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
}
.book_inner_container
{   width:var(--container_1_width);
    background: rgba(225, 224, 216, 0.889);
    backdrop-filter: blur(10px);
    // border: 1px solid;
    overflow-y: scroll;
    padding: 46px 56px;
    cursor: pointer;
    &::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
        height: 8px; /* Height of the scrollbar (for horizontal scrollbar) */
        margin-right: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Rounded corners of the scrollbar thumb */
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color of the scrollbar thumb on hover */
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color of the scrollbar track */
        border-radius: 4px; /* Rounded corners of the scrollbar track */
    }
}
.grid {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    height: min-content;
}
.books_section{
    margin:56px auto; 
}
.image {
    position: relative;
    max-width: 217.33px;
    height: 281px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    img{
        position: relative;
        object-fit: contain;
        max-width: 85%;
        width: 100%;
        z-index: 1;
 
    }
}
.image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px); /* Adjust blur amount as needed */
    background: inherit; /* Ensures the same background as the parent */
    z-index: 0;
    filter: blur(5px);
}
.title {
    font-family: Epilogue;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 24px;
    max-width: 217.33px;
}

.about {
    font-family: Epilogue;
    font-size: 14px;
    font-weight: 400;
    line-height: 14.35px;
}

.container {
    align-self: center;
    gap: 10px;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
}

.filter,
.active_filter {
    white-space: nowrap;
    height: 52px;
    padding: 16px;
    border-radius: calc(52px / 2);
    border: 1px solid black;
    cursor: pointer;
    font-family: Epilogue;
    font-weight: 600;
}

.active_filter {
    background-color: black;
    color: white;
}

.heading {
    font-family: Epilogue;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
}

.description {
    font-family: Epilogue;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.5px;
    width: 80%;
    margin-top: 24px;
    margin-bottom: 24px;
}
.book_close{
    position: absolute;
    right: 40px;
    font-size: 22px;
    font-weight: 550;
    cursor: pointer;
    font-family: Epilogue;
}

.tooltip_class
{
    background-color: var(--white_color)!important;
    font-size: 16px!important;
    color: var(--title-black)!important;
    max-width: 300px!important;
    line-height: 1.3!important;
    opacity: 1!important;
    font-family: Epilogue;
    font-weight: 500!important;
    z-index: 23211!important;
}

@media (max-width:600px){
    .container {
     flex-wrap: wrap;
    }
    .books_section{
        display: flex;
        flex-wrap: wrap;
    }
    .book_container{
        width: 48%;
    }
    .book_popup_container{
        padding: 36px 10px;
        .book_inner_container{
            padding: 13px;
            .book_container{
                width: 48%;
            }
            .grid {
                display: flex;
                flex-wrap: wrap;
            }
            .description{
                width: 100%;
            }
            .image {
                height: 231px;
            }
        }
    }
}