.timer{
    font-family: "Roboto Slab", serif;
    font-weight: 900;
    font-size: 151px;
    margin-top:16px;
    color: var(--title-black);
}
.outer{
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 20%;
    }
    .title{
        font-size: 61px;
        font-weight: 900;
        font-family: "Roboto Slab", serif;;
        margin-top: 16px;
        // color: white;
        background: #1f2023;
        color: #1f2023;
        padding: 20px 40px;
        cursor: not-allowed;
        transition: 0.5s;
        &:hover{
            color:black;
            // background: var(--background);
            border: 2px solid #1586c9;
            box-shadow: inset 800px 0 0 0 var(--background);
            color: black;
        }
    }
    .launch_image{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

@media(max-width:600px){
    .timer{
        font-size: 54px;
    }
 .inner{
    .title{
        font-size: 32px;
    }
    img{
        width: 200px!important;
    }
 }
}