.home{
    width: 100%;
    margin-top:32px;
}
.content {
    position: relative;
    display: flex;
    --gap: 24px;
    padding: 32px 0px;
    gap: var(--gap);

    .featured_stories {
        width: 74.5%;
    }
    
    .latest {
       
        width: calc(40% - var(--gap));
    }
}

@media (max-width:600px){
    .home{
        padding: 0px 16px;
    }
    .content {
        flex-direction: column;
        padding: 32px 0px;
    
        .featured_stories {
            width: 100%;
        }
        
        .latest {
           
            width: 100%;
        }
    }
}