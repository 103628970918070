.form {
    display: flex;
    flex-direction: column;
    width: max-content;
}

.question {
    margin-top: 48px;
    font-family: Epilogue;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);

    &::before {
        content: "* ";
        color: red;
    }
}
.question.not_required{
    &::before{
        content: "";
    }
}

.input,
.textarea,
.link {
    margin-top: 8px;
    border: 1px solid rgba(0, 0, 0, 1);
    background-color: white;
    border-radius: 2px;
    min-height: 32px;
    padding-left: 12px;

    &::placeholder {
        font-family: Epilogue;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
    }
}

.textarea {
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 38px;
}

.gap {
    height: 48px;
}

.link {
    padding-left: 40px;
    background-image: url("/vector/link.svg");
    background-size: 20px;
    background-position: 10px center;
    background-repeat: no-repeat;
}

.question_heading {
    margin-top: 24px;
    font-family: Epilogue;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    width: 100%;
}