.bread_crumbs
{
    padding-top: calc(var(--gap) + 10px);
}
.case_studies_details
{
    margin: auto;
    margin-top: 76px;
    width: max-content;
    font-family: Epilogue;
}
.container {
    width: var(--container_2_width);
 
    .image {
        width: 100%;
        margin-bottom: 43px;
    }
    .case_study_title_panel
    {
        padding: 16px 0px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 16px;
        color: #000000D9;
        border-bottom: 1px solid #00000057;
        margin-bottom: 16px;
    }
    .blog_tags{
        margin-top: 18px;
        margin-bottom: 18px;
        color: #000000D9;
    }
    .heading{
        font-size: 57px;
        font-weight: 700;
        color: #252525;
    }
    .sub_heading
    {
        font-size: 28px;
        color: #000000A3;
        line-height: 1.6;
        padding: 16px 0px;
        border-bottom: 1px solid #000000A3;
    }
    .description {
       margin: 43px 0px;
       font-family: Epilogue;
    }

    .relevant_links {
   
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #000000;
        div{
            margin-top: 20px;
            font-weight: 400;
            a{
            color: black;
            }
        }
    }

    .more {
        cursor: pointer;
        padding: 29px 16px;
        display: flex;
        justify-content: space-between;
        background-color: white;
        margin: var(--gap) 0px;
        border: 2px solid black;
        transition: padding 0.3s ease;

        &:after {
            content: url("/vector/right_arrow.svg");
        }

        a {
            text-decoration: none;
        }

        &:hover {
            padding-right: 8px;
        }
    }
}

.nav_row {
    margin-top: 48px;
    display: flex;
    gap: 48px;
}

.nav_container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #00000060;
    padding: 24px 0px;
    gap: 24px;

    img {
        height: 96px;
        width: 96px;
    }

    .content {
        .caption {
            font-family: 'Epilogue';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;

            &::after {
                size: 5px;
                content: url("/vector/right_arrow.svg");
            }
        }

        .title {
            margin-top: 20px;
            width: 240px;
            font-family: 'Epilogue';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.25px;

        }

        &[previous=true] {
            display: flex;
            flex-direction: column;
            align-items: end;

            .caption {
             
                align-items: end;

                &::before {
                    content: url("/vector/left_arrow.svg");
                }

                &::after {
                    content: none;
                }
            }

            .title {
                text-align: end;
            }
        }
    }
}

.nav_container[previous=true] {
    .content {
        .caption {
            &::before {
                size: 5px;
                content: url("/vector/right_arrow.svg");
            }
        }
    }
}


@media(max-width:600px){
    .container{
        width: 100%;
        max-width: 100%;
    }
    .case_studies_details{
        width: 100%;
        padding: 16px;
    }
    .nav_row{
        flex-direction: column;
    }
    .nav_container:nth-child(odd){
       flex-direction: row-reverse;
    }
}