.about_us {
    width: 698px;
    display: flex;
    flex-direction: column;
    gap: 56px;
    margin: auto;
}

.case_study_title_panel
{
    padding: 16px 0px 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 32px;
    font-weight: 700;
    font-family: Epilogue;
    font-style: italic;
    color: #252525;
    border-bottom: 1px solid #7c7c7c9a;

}
.case_study_outer
{
    background-color: #252525;
    margin-top: var(--gap);
}

@media(max-width:600px)
{
    .about_us{
        width: 100%;
     
    }
    .container{
        padding: 16px;
    }
}