.heading {
    font-family: Epilogue;
    font-size: 32px;
    font-style: italic;
    font-weight: 700;
    line-height: 32.8px;
    padding: 56px 0px 24px 0px;
    border-bottom: 1px solid grey;
    margin-bottom: 24px;
}

.title {
    font-family: Source Serif 4;
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    line-height: 24px
}

.names {
    font-family: Source Serif 4;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 26px;
}
.credits_detail{
    font-family: Epilogue;
    line-height: 1.5;
}