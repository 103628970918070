.loader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        width: 100px;
        height: auto;
    }
    p{
        font-family: Epilogue;
    }
}