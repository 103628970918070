.container{
    position: relative;
}
.different_section
{
font-size: 32px;
font-weight: 700;
color: var(--primary-color);
font-family: Epilogue;
margin-top: 56px;
}
.different_section_2
{
font-size: 32px;
font-weight: 400;
color: var(--primary-color);
font-family: Epilogue;
margin-bottom: 56px;

text-align: center;
}
.spacing_20{
    height: 20px;
}
.spacing_50{
    height: 50px;
}
.spacing_70{
    height: 70px;
}

@media(max-width:600px){
    .container{
       padding-left: 16px;
       padding-right: 16px;
    }
    .different_section{
        width: 100%;
    }
}
