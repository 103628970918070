.card {
    padding: 16px;
    border: 1px solid black;
    opacity: 1px;
    background-color: var(--card-background);
}

.image {
    width: 100%;
}

.caption {
    padding: 2px 4px 2px 4px;
    gap: 4px;
    border-radius: 2px;
    width: fit-content;
    margin: 20px 0px;
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    background-color: var(--background);

    .left_line {
        width: 4px;
        height: 16px;
    }
}

.title {

}

.description {
    margin-top: 10px;
    color: #000000A3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    // text-transform: lowercase;
}
