.footer_container{
    font-family: Epilogue;
padding-top: 56px;
    a{
        text-decoration: none;
    }
}

.footer_inner
{
    border-top: 4px solid var(--primary-color);
    padding-top: 56px;
    display: grid;
    grid-template-columns: 35% 10% 35%;
    justify-content: space-between;
    margin-bottom: 29px;
}

.footer_title
{
    margin-bottom: 25px;
}
.footer_body
{
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
    .footer_body_p{
        margin-bottom: 12px;
        a{
            color: var(--primary-color);
        }
    }
    .footer_img
    {
        margin-top: 66px;
        width: 100%;
        text-align: right;
        img{
            width: 120px;
            height: 42px;
        }
    }
}
.footer_social_media
{
    display: flex;
    gap: 16px;
    align-items: center;
    a{
        border: 1px solid var(--primary-color) ;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 12px;
        border-radius: 14px;
       
        width: max-content;
        img
        {
            height: 16px;
            width: 15px;
          
        }
    }
}

// css for footer links in sitemap/ second column of footer

.footer_links_outer
{
    display: flex;
    flex-direction: column;
    gap: 20px;
    a{
        color: var(--primary-color);
        font-size: 16px;
    }
    
}

// css for third column of footer
.footer_newsletter
{
    border: 1.5px solid var(--primary-color);
    padding: 16px;
    margin-bottom: 31px;
    .footer_newsltr_title
    {
       font-weight: bold;
       font-size: 16px;
    }
    .footer_newsltr_body
    {
        margin-top: 24px;
        margin-bottom: 12px;
        color: #00000099;
        font-weight: 400;
    }
    .footer_newsltr_form
    {
        input{
            width: 100%;
            font-size: 16px;
            padding: 10px;
            outline: none;
            font-family: Epilogue;
        }
        .footer_newsltr_subscribe_btn{
            margin-top: 12px;
            padding: 10px;
            width: 100%;
            text-align: center;
            background: var(--primary-color);
            color: var(--white_color);
            font-weight: bold;
            cursor: pointer;
        }
    }
}

// bottom footer css class

.footer_bottom{
    border-top: 1px solid #0000003b;
    ;
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    a{
        color: var(--primary-color);
    }
    .footer_bottom_links
    {
        display: flex;
        gap: 29px;
    }
}

.message{
    margin:16px 0px;
    .error_message{
        color: var(--sflc-red);
    }
    .success_message{
        color:rgb(120, 165, 112);
    }
}


@media (max-width:1210px) {
    .footer_col{
        margin-left:7px ;
    }
}
@media (max-width:710px){

    .footer_bottom_links
    {
        margin-left: 12px;
    }
    .footer_bottom_text{
        margin-left: 35px;
    }
}




@media (max-width:600px){

    .footer_inner{
        display: flex;
        gap: 20px;
        flex-direction: column; 
        align-items: center;
        flex-wrap: wrap;
        .footer_col{
            width: 90%;
        }
    }
    .footer_col{
        margin-left:2px ;
    }
}

