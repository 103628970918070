
.container
{
    font-family: Epilogue;
}
.contact_heading{
font-size: 48px;
line-height: 38px;
font-weight: 700;
display: flex;
font-style: italic;
justify-content: space-between;
padding: 13x 0px;
border-bottom: 1px solid var(--lighter-grey);
margin-top: 16px;
}
.cont_desc{
    margin-top: 56px;
font-size: 22px;
font-weight: 400;
line-height: 33px;
letter-spacing: 0.5px;
text-align: center;
text-align: justify;

}

.second_secion{
    margin-top: 56px;
    display: flex;
    gap: 24px;
   .submit_data{
    padding: 24px;
    background: #D2D1CA;
    border: 1px solid ;
   
    h2{
        color: var(--title-black);
        margin-bottom: 9px;
        font-size: 30px;
        line-height: 34px;
    }
    ul{
        margin-left: 30px;
       line-height: 24px;
       font-size: 16px;
    }
    p{
        margin-top: 10px;
        line-height: 1.5;
        a{
            text-decoration: none;
            color: var(--title-black);
        }
    }
   }
    .submit_data:first-child{
        width: 50%;
    }
    .submit_data:last-child{
        width: calc(50% - 24px);
        line-height: 24px;
        font-size: 16px;
    }
}
.third_section
{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    background: #D2D1CA;
    border: 1px solid;
    h2{
        margin-bottom: 9px;
font-size: 30px;
font-weight: 700;
line-height: 34px;
letter-spacing: 0.25px;
text-align: center;

    }
    p{

font-family: Epilogue;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
line-height: 1.5;
    }
    a{
        margin-top: 10px;
        width: max-content;
        padding: 10px 80px;
        background: black;
        text-decoration: none;
        color: white;
    }
}

.forth_section{
    background: #D2D1CA;
    padding: 24px;
    border: 1px solid;
    margin-top: 24px;
    h2{
        margin-bottom: 9px;
font-size: 30px;
font-weight: 700;
line-height: 34px;
letter-spacing: 0.25px;
text-align: left;

    }
    p{

font-family: Epilogue;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
line-height: 1.5;
    }
}

.footer_social_media
{
    margin-top: 24px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    a{
        border: 1px solid var(--primary-color) ;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 12px;
        border-radius: 14px;
       
        width: max-content;
        img
        {
            height: 16px;
            width: 15px;
          
        }
    }
}

.improve_the_website{
    margin-top: 56px;
font-size: 32px;
font-style: italic;
font-weight: 700;
line-height: 48px;
text-align: center;
margin-bottom: 10px;
}
.improve_the_website_sub{
// font-family: Epilogue;
font-size: 22px;
font-weight: 400;
line-height: 33px;
letter-spacing: 0.5px;
text-align: center;
margin-bottom: 56px;
}

.fifth_section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    .col_1{
    padding: 24px;
    border: 1px solid;
    background: #D2D1CA;
    // width: 48%;
        display: grid;
    flex: 1;
    h2{

font-size: 30px;
font-weight: 700;
line-height: 34px;
letter-spacing: 0.25px;
text-align: left;
margin-bottom: 9px;
    }
    p{
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
margin-bottom: 24px;
    }
    a{
        text-decoration: none;
        width: 100%;
        padding: 10px 80px;
        background: black;
        text-decoration: none;
        color: white;
        text-align: center;
    }
    &.col_1:last-child a{
        align-self: end;
    }
    }
}

.donate_section{
    display: flex;
    gap:24px;
    .donate_col{
        padding: 24px;
        width: calc(33% - 24px);
        background: #D2D1CA;
        border: 1px solid;
 
        h1{

            font-size: 30px;
            font-weight: 700;
            line-height: 34px;
            letter-spacing: 0.25px;
            text-align: left;
            margin-bottom: 9px;
                }
                p{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            margin-bottom: 10px;
                }
                ul{
                    margin-left: 30px;
                   line-height: 24px;
                   font-size: 16px;
                   margin-bottom: 10px;
                }
                a{
                    text-decoration: none;
                    width: 100%;
                    padding: 10px 80px;
                    background: black;
                    text-decoration: none;
                    color: white;
                    text-align: center;
                    align-items: center;
                    &.donate_btn{
                        padding-top: 15px;
                    }
                    
                }
                &:nth-child(2){
                   display: grid;
                }
            }
}


//mobile view

@media(max-width:600px){
    .container{
        padding: 16px;
    }
    .second_secion{
        flex-direction: column;
        .submit_data:first-child{
            width: 100%;
        }
        .submit_data:last-child{
            width: 100%;
           
        }
    }
    .fifth_section{
        display: flex;
        flex-direction: column;
    }
    .donate_section{
        display: flex;
        flex-direction: column;
        .donate_col{
            width: 100%;
        }
    }
}