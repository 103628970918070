.featured_stories {
    width: 100%;
}


.containers {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
}

.heading {
    font-family: Epilogue;
    font-size: 34px;
    font-style: italic;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.0025em;
    padding-bottom: 24px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    div{
        font-size:14px;
        color: #252525;
    font-style: normal;
    font-weight:400;
cursor: pointer;

    }
}

// mobile view

@media (max-width:600px){
   .containers{
    display: flex;
    flex-direction: column;
   }
}