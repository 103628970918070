.container{
    padding: 32px 16px;
}
.different_section
{
font-size: 32px;
font-weight: 700;
color: var(--primary-color);
font-family: Epilogue;
margin-top: 56px;
}
.spacing_20{
    height: 20px;
}
.spacing_50{
    height: 50px;
}
.spacing_70{
    height: 70px;
}
.lets_explore
{

font-family: Epilogue;
font-size: 32px;
font-style: italic;
font-weight: 700;
line-height: 48px;
text-align: center;
margin: 38px 0;

}

.post_kind_word_cloud{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 817px;
    margin: auto;
    gap: 62px;
    margin-bottom: 99px;
    margin-top: 52px;
    // background: #44325E0A;
    padding: 32px 1px;
    font-family: Epilogue;
    p{
        font-size: 28px;
        margin: unset;
        line-height: 10px;
        // line-height: 62.59px;
    img{
     cursor: help;
    }
    }
    p.red_p{
        color: var(--sflc-red);
    }
}

.circle_parking_chart{
    svg{
        transform: rotate(90deg) scale(1.5);
        width:max-content;
        margin: auto;
        g{
            circle:first-child{
                display: none;
            }
        }
    }
}

.tooltip_class
{
    background-color: var(--white_color)!important;
    font-size: 16px!important;
    color: var(--title-black)!important;
    max-width: 300px!important;
    line-height: 1.3!important;
    opacity: 1!important;
    font-family: Epilogue;
    font-weight: 500!important;
}
.download_btn{
    position: relative;
    z-index: 1;
}

.clicked_data{
    border: 1px solid #bab7b7;
    border-radius: 2px;
    padding: 20px 20px;
    background: #cbcac0;
    p{
        font-size: 15px;
        font-family:Epilogue ;
        margin-bottom: 10px;
    }
    ul{
        margin-left: 20px;
        li{
            p{
                a{
                    text-decoration: none;
                    color: rgb(12, 181, 232);
                }
            }
        }
    }
}

@media (max-width:600px){
    .circle_parking_chart{
        height: max-content!important;
        padding-bottom: 20px;
    }
    .circle_parking_chart{
        
        svg{
            transform: rotate(0deg) scale(1);
            width:max-content;
            margin: auto;
            g{
                circle:first-child 
                {
                    display: none;
                }
            }
        }
    }
    .post_kind_word_cloud {
        justify-content: flex-start;
    align-items: center;
    max-width: 817px;
    margin: auto;
    gap: 25px;
p{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
    }
    .circle_legend{
        justify-content: flex-start!important;
    }
}