.container
{
    margin: auto;
    width: var(--max-width);
    padding: var(--gap);
    margin-bottom: var(--gap);
    a{
        text-decoration: none;
    }
}

.case_studies {
   

    display: flex;
    height: 594px;
}
.case_studies.reverse {
    display: flex;
    height: 594px;
    flex-direction: row-reverse;
}
.content {
    padding: 121px 37px;
    width: 50%;
    display: flex;
    flex-direction: column;

    .heading {
        font-family: Epilogue;
        font-size: 34px;
        font-style: italic;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0.0025em;
        text-align: left;
    }
    .caption {
        gap: 4px;
        border-radius: 2px;
        width: fit-content;
        margin-top: 36px;
        padding: 2px 4px;
        border-radius: 2px;
        display: flex;
        background-color: var(--background);

        .left_line {
            width: 4px;
            height: 16px;
        }
    }

    .title {
        padding: 20px 0px;
        color: var(--background);
        font-size: 30px;
    }
    .description{
        color: var(--background);
        font-family: Epilogue;
        font-size: 22px;
        line-height: 1.6;
        font-weight: 300;
    }

    .gap {
        flex-grow: 1;
    }

 
}

.image {
    width: 50%;
    object-fit: cover;
}

.case_study_title_panel
{
    padding: 16px 0px 41px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 48px;
    font-weight: 700;
    font-family: Epilogue;
    font-style: italic;
    color: var(--background);
    border-bottom: 1px solid #7c7c7c9a;
    margin-bottom: var(--gap);
}
.case_study_outer
{
    background-color: #252525;
    margin-top: var(--gap);
}
.pagination{
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    gap:10px;
    button{
        padding: 10px 16px;
        cursor: pointer;
        font-family: Epilogue;
        font-weight:500;
        font-size: 18px;
    }
}

@media (max-width:600px) {
    .container{
        width: 100%;
        padding: 16px;
        .case_study_container{
            display: flex;
            flex-direction: column;
            gap: 32px;
        }
        .case_study_container > a{
            display: flex;
            flex-direction: column;
            .content{
                width: 100%;
                padding: 16px;
            }
            img{
                width:100%
            }
        }
        .case_studies{
            height: max-content;
        }
        .case_studies.reverse{
            flex-direction: column;
        }
    }
    
}