.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 674px;
    padding: 21px 48px;
    background-color: rgba(68, 50, 94, 1);

    .question {
        font-family: Epilogue;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        align-self: flex-start;
        text-align: left;
        color: var(--background);
    }

    .instructions {
        font-family: Epilogue;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        align-self: flex-start;
        color: var(--background);

    }

    // remove first of type after fixing input
    input:first-of-type {
        margin-top: 95px;
    }
   .input_range
    {
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        background-color: #ffd35029;
        border-radius: 50px;
        height: 7px;
    
        &::-webkit-slider-thumb {
            height: 24px;
            width: 24px;
            opacity: 0.7;
            // margin-top: -4px;
            border-radius: 25px;
            background: var(--sflc-yellow);
            cursor: pointer;
            -webkit-appearance: none;
    
        }
        &.input_range_correct
        {
    
            &::-webkit-slider-thumb{
                background: #78A57099;
              
            }
        }
        &.input_range_wrong
        {
            margin-top:-7px;
            &::-webkit-slider-thumb{
                background: #D96A6A;
            }
        }
    }

    
    .text {
        margin-top: 95px;
        font-family: Epilogue;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: justified;
        color: rgba(225, 224, 216, 1);
    }

    .image {
        margin-top: 24px;
        height: 164px;
    }

    .button {
        border: none;
        margin-top: 96px;
        background-color: rgba(255, 212, 80, 1);
        width: 185px;
        height: 51px;
        font-family: Epilogue;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.49px;
        text-align: center;
    }
}
.current_value
{
    color: var(--background);
    font-family: Epilogue;
    margin-top: 36px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span:first-child{
       color: #D96A6A;
       margin-bottom: 16px;
    }
    span:last-child{
        color: #78A57099;
    }
}