.heading {
    font-family: Epilogue;
    font-size: 32px;
    font-style: italic;
    font-weight: 700;
    line-height: 32.8px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.sub_heading
{
    padding-bottom: 24px;
    border-bottom: 1px solid grey;
    margin-bottom: var(--gap);
    font-size: 19px;
    font-family: Epilogue;
    line-height: 32.8px;
    text-align: justify;
}

.card {
    font-family: Epilogue;
    border-bottom: 0.5px solid #00000047;
    cursor: pointer;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0px;

        .title {
            margin: 26px 0px;
            font-family: Epilogue;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            cursor: pointer;
        }

        .collapse {
            padding: 5px;

            .rotate {
                transition: transform 0.5s ease-in-out;
                transform-origin: center;
            }
        }

    }

    .callapsed_content {
        display: grid;
        font-family: Epilogue;
        grid-template-rows: 0fr;
        padding-bottom: 0px;
        line-height: 1.7;
        transition: grid-template-rows 0.5s ease-out, padding-bottom 0.5s ease-out;

        .description {
            overflow: hidden;
        }
    }

}

.expanded {
    .rotate {
        transform: rotate(90deg);
    }

    .callapsed_content {
        grid-template-rows: 1fr;
        padding-bottom: 26px;
        .description{
            a{
                color: var(--title-black);
            }
        }
    }
}