.container
{
   font-family: Epilogue;
}
.spectrum
{
    color:var(--sflc-red);
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
    p{
        margin: 29px 0px;
    }
}
.post_kind
{
    color:#3F295F;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
    p{
        margin: 29px 0px;
    }
    .post_kind_word_cloud{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: 817px;
        margin: auto;
        gap: 73px;
        margin-bottom: 99px;
        margin-top: 52px;
        background: #44325E0A;
        padding: 32px 37px;
        p{
            font-size: 61px;
            margin: unset;
     
            // line-height: 62.59px;
        img{
         cursor: help;
        }
        }
    }
}

.tooltip_class
{
    background-color: var(--white_color)!important;
    font-size: 16px!important;
    color: var(--title-black)!important;
    max-width: 300px!important;
    line-height: 1.3!important;
    opacity: 1!important;
    font-family: Epilogue;
    font-weight: 500!important;
    z-index: 23211!important;

}

@media (max-width:600px){
    .container{
        padding: 32px 16px;
    }
    .spectrum img{
        max-width: 100%;
    }
    .post_kind{
        .post_kind_word_cloud {
            gap: 2px;
            p{
                font-size: 43px;
            }
    
        }
    }

}