
// desktop view

.header_container
{
    font-family: "Roboto Slab", serif;
    font-family: Roboto Slab;
    font-optical-sizing: auto;
    font-style: normal;
    width: 100%;
}
.header_container a
{
    text-decoration: none;

}
.header_date
{
    div{
        display: none;
    }
    width: 100%;
    // font-size:;
    font-weight: bold;
    border-bottom: 8px solid;
    padding-bottom: 8px;
  padding-top: 8px;
  font-family: Epilogue;
}
.current_date{
    font-weight: 600;
    font-family: Epilogue;
}
.header_extra
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: first baseline;
}
.header_center_area
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 11px;
}

.header_sflcinlogo img
{
    width: 20%;
}
.header_title{
    font-size: 100px;
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    font-family: Roboto Slab;
    font-weight: 900;
    color: var(--primary-color);
    margin-top: -28px;
    margin-bottom: -15px;
}
.header_donate_btn
{
    font-family: Epilogue;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--primary-color);
    padding:16px 35px;
    border: 1px solid;
    border-radius: 63px;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
}
.header_donate_btn:hover{
    background: var(--primary-color);
    color: var(--white_color);
}

// header nav bar links

.header_nav_bar, .header_mobile_nav
{
    background: var(--primary-color);
    display: flex;
    // align-items: center;
    // justify-content: center;
    padding-left: 20px;
    gap: 24px;
    a{
        color: var(--white_color);
        font-weight: 700;
        padding-top: 14px;
        padding-bottom: 14px;
        font-family: Epilogue;
    }
    a.nav_donate{
        border: 1px solid white;
        padding: 5px;
        margin-top: 8px;
        height: max-content;
        transition: background-color 0.5s ease-in-out;
        &:hover{
            background: var(--background);
            color: var(--primary-color);
        }
    }
    .navbar_dropdown_container
    {
        position: relative;
        display: flex;
        // justify-content: center;
        // align-items: center;
        flex-direction: column;

        .nav_link_dropdown {
            position: relative;
            flex-direction: column;
            display: flex;
            // align-items: center;
            margin-right: 20px;
            gap: 10px;
            span{
                width: max-content;
                position: relative;

                &::after{
                    position: absolute;
                    content: url("./caret2-down.svg");
                    right:-25px
                }
            }
            &:hover{

                span{
                    &::after{
                        transform: rotate(180deg);
                    } 
                }
                .nav_dropdown{
                    display: flex;
                }
            }
            img{
                width: 20px;
                display: none
            };
        }
    }
  
    .nav_dropdown
    {
        position: relative;
        display: none;
        flex-direction: column;
        margin-top: 10px;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        // width: max-content;
        // background: var(--white_color);
        .nav_links
        {
            padding:15px 0px;
            color: var(--white_color);
            transition: background-color ease-in-out 0.1s;
        }
    }
}

//mobile view

@media (max-width:600px) {
  
    .header_container{
        display: flex;
        flex-direction: column;
        // gap:30px;
    .header_date{
        padding: 0px 16px 16px 16px;
        order: 2;
        border-bottom: unset;
        div{
            display: block;
        }
    }
    .header_center_area{
        padding: 16px 16px 0px 16px;
        order: 1;
        .current_date{
            display: none;
        }
        .header_title{
            margin-top: 8px;
            font-size: 49px;
            line-height: 1;
            margin-bottom: 8px;
        }
        .header_sflcinlogo img{
            width: 25%;
        }
        .header_extra{
            display: flex;
            align-items: center;
        }
    }
    .header_nav_bar{
        position: absolute;
        display: none;
        top: 90px;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--background);
        flex-direction: column;
        z-index: 1231;

        a.nav_donate{
            border: unset;
        }
        .nav_links, .nav_link_dropdown a{
            color: var(--title-black);
            font-size: 32px;
            font-weight: 700;
        }
        
        .nav_link_dropdown{
            a{
                font-size: 28px;
                margin-left: 20px;
            }
            span{
                &::after{
                    content: url("./caret2-down-black.svg");
                }
            }
        }
    }
    .header_nav_bar.active{
        display: flex;
    }
    .header_nav_outer{
        order: 4;
    }
    .header_mobile_nav{
        padding: 9px 16px; 
        display: flex!important; 
        justify-content: space-between;
        align-items: center;

        .nav_donate{
            padding: 8px 10px;
            margin-top: unset;
        }

        .nav_menu {
            display: flex;
            align-items: center;
            gap: 20px;
            cursor: pointer;
          
            img{
                width: 24px;
                height: 24px;
            }
            span{
                color: white;
                font-family: Epilogue;
                font-weight: 700;
                font-size: 16px;
            }
         
        }
      
    }
    }
}
