.heading {
    font-family: Epilogue;
    font-size: 34px;
    font-style: italic;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.0025em;
    padding-bottom: 24px;
}


.caption {
    font-family: Epilogue;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 12px;
}

.title {
    font-family: Epilogue;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    color: var(--title-black);
}

.description {
    max-height: 51px;
    overflow: hidden;
}

.image {
    width: 96px;
    height: 96px;
}

.story {
    padding: 16px 16px 23px 16px;
    border: 1px solid black;
    height: 135px;
    overflow: hidden;
    background-color: var(--card-background);
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    text-decoration: none;
}

.latest {
    padding-left: 10px;
    // padding-right: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}