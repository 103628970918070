.timer{
    font-family: "Roboto Slab", serif;
    font-weight: 900;
    font-size: 151px;
    margin-top:16px;
    color: var(--title-black);
}
.outer{
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
        font-size: 61px;
        font-weight: 900;
        font-family: "Roboto Slab", serif;;
        margin-top: 16px;
        color: var(--title-black);
    }
}


@media(max-width:600px){
    .timer{
        font-size: 54px;
    }
 .inner{
    .title{
        font-size: 32px;
    }
    img{
        width: 200px!important;
    }
 }
}