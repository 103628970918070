.shareContainer{
   position: relative;
   font-family: Epilogue;
   transition: 2s ease-in-out;
   cursor: pointer;
   z-index: 23434;
}
.social_share_icon{
   
}

.copy_btn{
width: 16px;
height: 16px;
    path{
        fill:var(--title-black);
    }

}
.link_btn{
    width: 24px;

height: 24px;
    path{
        fill:var(--background);
    }
}
.shareBtnContainer
{
    font-style: normal;
    position: absolute;
    right: 100%;
    display: none;
    background: var(--title-black);
    padding: 24px;
    p{
        font-size:22px;
        line-height: 33px;
        color: var(--background);
       
    }
    .share_btn_row1
    {
        display: flex;
        margin-bottom: 24px;
        margin-top: 10px;
        gap: 12px;
        align-items: center;
        .share_r1_btn1{
            flex-grow: 1;
            display: flex;
            align-items: center;
            gap: 12px;
            input{
                color: #000000A3;
                background: #ffffff;
                outline: none;
                border: 1px solid var(--background);
                padding: 10px 17.56px;
                font-size: 14px;
                font-family: Epilogue;
                font-weight: 550;
                width: 100%;
            }
        }

        .copy_link_btn{
            background-color: var(--background);
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            color: var(--title-black);
            padding: 11px 16px;
            cursor: copy;
            &:active{
               outline: 2px solid var(--sflc-red);
            }
        }
    }

    .share_icons{
        display: flex;
        gap: 12px;
        a{
            
        }
    }
}

@media (max-width:600px){
    .shareBtnContainer{
        transform: scale(0.7);
        right: -57px;
        top: 20px;
    }
}