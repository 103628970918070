
.description {
    font-family: Epilogue;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.5px;
    text-align: justify;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.feedback {
    text-align: justify;
    font-family: Epilogue;
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0.5px;

    a:link {
        color: #BD0101;
        text-decoration: none;
    }
}