.title {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;

}

.option {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

.radio {
    margin-right: 8px;
}